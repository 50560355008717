/* eslint-disable */
export function init() {
  const items = document.querySelectorAll(".list--item");
  const dropdownBackground = document.querySelector("#navigation--dropdown");
  function handleEnter() {
    const dropdown = this.querySelector(".item--dropdown");
    const nav = document.querySelector(".navigation__list");
    const navActive = document.querySelector(".active-nav-menu");
    if (dropdown == null) {
      return;
    }
    const { offsetWidth: width, offsetHeight: height } = dropdown;
    const rectangle = dropdown.getBoundingClientRect();
    const navERectangle = nav.getBoundingClientRect();
    const { top, left } = rectangle;

    dropdownBackground.classList.add("active");
    dropdown.classList.add("active");
    if (navActive && navActive.style) {
      navActive.style.display = "none";
    }
    dropdownBackground.style.width = `${width}px`;
    dropdownBackground.style.height = `${height}px`;
    dropdownBackground.style.top = `${top}px`;
    dropdownBackground.style.left = `${left - navERectangle.left}px`;
  }

  function handleExit() {
    const dropdown = this.querySelector(".item--dropdown");
    const navActive = document.querySelector(".active-nav-menu");
    if (navActive && navActive.style) {
      navActive.style.display = "block";
    }

    if (!dropdown) {
      return;
    }
    dropdown.classList.remove("active");
    dropdownBackground.classList.remove("active");
  }

  items.forEach((item) => item.addEventListener("mouseenter", handleEnter));
  items.forEach((item) => item.addEventListener("mouseleave", handleExit));
}
