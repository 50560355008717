import { Modal } from "antd";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo, ReactNode, useMemo, useState } from "react";
import { wechatQRtoBusiness, wechatQRtoVendor, white_logo } from "../images";
import { LinkIn, Wechat, ZhiHu } from "../images/index/index";
import { chunkItems } from "../utils/utils";
import { ChildrenProps, SkewMainContainer } from "./Container";
import FloatContact from "./FloatContact";
import { useLinkMenus } from "./Header";

type menuItem = {
  menuText: string;
  url: string;
};

type MenuList = {
  title: string;
  menus: menuItem[];
};

const MenuTitle = (props: ChildrenProps) => {
  return (
    <h6 className="text-base text-blue whitespace-nowrap mb-4">
      {props.children}
    </h6>
  );
};

const AddressItem = (props: ChildrenProps) => {
  return (
    <p className="text-blue text-xs sm:text-sm leading-2 sm:leading-2 mb-4">
      {props.children}
    </p>
  );
};

type LinkItem = {
  icon: ReactNode;
  url?: string;
  type?: string;
};

const linkIcons: LinkItem[] = [
  {
    icon: <LinkIn />,
    url: "https://www.linkedin.com/company/stardust-ai-data",
  },
  {
    icon: <Wechat />,
    type: "modal",
  },
  {
    icon: <ZhiHu />,
    url: "https://zhuanlan.zhihu.com/c_1148208696742977536",
  },
];

const AddressView = memo(() => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <MenuTitle>{t("联系我们")}</MenuTitle>
      <AddressItem>
        {t("公司地址：北京市朝阳区东三环中路39号 建外SOHO东区B座20层")}
      </AddressItem>
      <AddressItem>
        {t("电话：")}
        <a className="text-blue" href="tel:010-86462864">
          (+86) 010-86462864
        </a>
      </AddressItem>
      <AddressItem>
        Email：
        <a className="text-blue" href="mailto:contacts@stardust.ai">
          contacts@stardust.ai
        </a>
      </AddressItem>
      <div className="flex mt-8">
        {linkIcons.map((item, index) => {
          return (
            <a
              target="_blank"
              href={item.url}
              key={index}
              className="w-8 h-8 rounded-full bg-white bg-opacity-10 flex justify-center items-center mr-4"
              onClick={() => {
                if (item.type && item.type === "modal") {
                  setVisible(true);
                  return;
                }
                window.open(item.url, "_blank");
              }}
            >
              {item.icon}
            </a>
          );
        })}
      </div>
      <WechatModal visible={visible} onCancel={() => setVisible(false)} />
    </div>
  );
});

export const WechatModal = memo(
  ({ visible, onCancel }: { visible: boolean; onCancel: () => void }) => {
    const { t } = useTranslation();
    const wechatModalInfo = useMemo(
      () => [
        // {
        //   img: wechatQRtoCS,
        //   title: t("星尘客服微信 (企业)："),
        // },
        {
          img: wechatQRtoBusiness,
          title: t("星尘数据 (官方)："),
        },
        {
          img: wechatQRtoVendor,
          title: t("标注学院 (工作招募)："),
        },
      ],
      [t]
    );
    return (
      <Modal
        title={t("关注微信公众号")}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={720}
      >
        <div className="grid grid-cols-2 gap-0">
          {wechatModalInfo.map((item, index) => (
            <div key={index} className="flex flex-col items-center">
              <p className="whitespace-nowrap text-sm">{item.title}</p>
              <img src={item.img} alt="" />
            </div>
          ))}
        </div>
      </Modal>
    );
  }
);

const StatementItem = (props: ChildrenProps & { url: string }) => {
  return (
    <a className="text-xs text-blue" href={props.url}>
      {props.children}
    </a>
  );
};

const StatementView = () => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2  mt-8 sm:mt-40 text-blue text-sm">
      <p className="text-xs mb-4 sm:mb-0">
        {t("© 2022 北京星尘纪元智能科技有限公司 保留所有权")}
      </p>
      <div className="flex justify-between sm:justify-end items-center pr-7 sm:pr-0">
        <StatementItem url="/privacy-policy">{t("隐私条款")}</StatementItem>
        <span className="sm:px-8">|</span>
        <StatementItem url="/disclaimer">{t("免责声明")}</StatementItem>
        <span className="sm:px-8">|</span>
        <StatementItem url="https://beian.miit.gov.cn">
          {t("京ICP备17065871号")}
        </StatementItem>
      </div>
    </div>
  );
};

const MenuItem = (props: ChildrenProps & { url: string }) => {
  return (
    <a
      className="text-xs sm:text-sm leading-8 sm:leading-8  whitespace-normal text-green"
      href={props.url}
    >
      {props.children}
    </a>
  );
};

const Footer = memo(() => {
  const { menus } = useLinkMenus();
  const footerMenuList: MenuList[][] = chunkItems(
    menus.filter((menu) => menu.menus.length > 0),
    2
  );
  return (
    <>
      <SkewMainContainer className="z-20" hideBottom={true}>
        <div className="relative z-[2]">
          <img
            className="hidden sm:block mt-4 mb-12 w-32"
            src={white_logo}
            alt=""
          />
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-16">
            {footerMenuList.map((lists, index) => {
              return (
                <div
                  key={index}
                  className="grid grid-cols-2 sm:grid-cols-1 gap-x-16 gap-y-16 mb-8"
                >
                  {lists.map((list, idx) => {
                    return (
                      <div
                        key={idx}
                        className="flex flex-col"
                        style={{ height: 300 }}
                      >
                        <MenuTitle>{list.title}</MenuTitle>
                        {list.menus.map((menu, i) => (
                          <MenuItem key={i} url={menu.url}>
                            {menu.menuText}
                          </MenuItem>
                        ))}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <AddressView />
          </div>
          <StatementView />
        </div>
      </SkewMainContainer>
      <FloatContact />
    </>
  );
});

export default Footer;
