import { Modal } from "antd";
import classNames from "classnames";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import ReactGA from "react-ga";
import { Sticky } from "react-sticky";
import { Close } from "../../images";
import { ReactComponent as ColorLogo } from "../../images/color-logo.svg";
import { ReactComponent as LanguageIcon } from "../../images/icon_language.svg";
import { ReactComponent as Logo } from "../../images/logo.svg";
import {
  menu_company_01,
  menu_company_02,
  menu_company_03,
  menu_company_04,
  menu_company_05,
  menu_company_06,
  menu_data_01,
  menu_data_02,
  menu_data_03,
  menu_download_01,
  menu_download_02,
  menu_price_01,
  menu_price_02,
  menu_price_03,
  menu_price_04,
  menu_product_01,
  menu_product_02,
  menu_product_03,
  menu_product_04,
  menu_product_05,
  menu_product_06,
  menu_product_07,
  menu_service_01,
  menu_service_02,
  menu_service_03,
  menu_service_04,
  menu_service_05,
  menu_service_06,
  menu_service_07,
  menu_service_08,
} from "../../images/nav-icon";
import { ReactComponent as ColorLogoEn } from "../../images/startdust_logo_color_en.svg";
import { ReactComponent as LogoEn } from "../../images/startdust_logo_white_en.svg";
import SearchInput from "../SearchInput";
import { MenuButton, NavMenu } from "./NavItem";
import PcMenuView from "./PcMenuView/PcMenuView";

type INavMenu = {
  menuText: string;
  url: string;
  icon?: string;
  isLink?: boolean;
};

type ListItem = {
  title: string;
  url: string;
  menus: INavMenu[];
};

export const useLinkMenus = () => {
  const { t } = useTranslation();
  const [menus] = useState<ListItem[]>([
    {
      title: t("数据服务"),
      url: "/",
      menus: [
        {
          menuText: t("服务场景"),
          url: "/#serviceScenario",
          icon: menu_service_01,
        },
        {
          menuText: t("服务流程"),
          url: "/#serviceProcess",
          icon: menu_service_02,
        },
        {
          menuText: t("服务优势"),
          url: "/#serviceCharacter",
          icon: menu_service_03,
        },
        {
          menuText: t("质量管控"),
          url: "/#qualityControl",
          icon: menu_service_04,
        },
        {
          menuText: t("数据安全"),
          url: "/#dataSecurity",
          icon: menu_service_05,
        },
        {
          menuText: t("数据交易平台"),
          url: "/#dataTradingPlatform",
          icon: menu_service_06,
        },
        {
          menuText: t("星尘的价值"),
          url: "/#stardustValue",
          icon: menu_service_07,
        },
        { menuText: t("客户"), url: "/#customer", icon: menu_service_08 },
      ],
    },
    {
      title: t("产品"),
      url: "/product/",
      menus: [
        {
          menuText: t("高效标注工具"),
          url: "/product/#computerVision",
          icon: menu_product_01,
        },
        {
          menuText: t("技术方案"),
          url: "/product/#technicalSolutions",
          icon: menu_product_02,
        },
        {
          menuText: t("数据采集与管理工具"),
          url: "/product/#completeDataTool",
          icon: menu_product_03,
        },
        {
          menuText: t("API接入"),
          url: "/product/#completeAPI",
          icon: menu_product_04,
        },
        {
          menuText: t("数据安全"),
          url: "/product/#dataSecurity",
          icon: menu_product_05,
        },
        {
          menuText: t("企业定制"),
          url: "/product/#enterpriseCustom",
          icon: menu_product_06,
        },
        {
          menuText: t("产品亮点"),
          url: "/product/#productHighlight",
          icon: menu_product_07,
        },
      ],
    },
    {
      title: t("客户案例"),
      url: "/customer-case/",
      menus: [],
    },
    {
      title: t("数据"),
      url: "/data/",
      menus: [
        {
          menuText: t("数据样例"),
          url: "/data/#dataSample",
          icon: menu_data_01,
        },
        {
          menuText: t("数据定制"),
          url: "/data/#customData",
          icon: menu_data_02,
        },
        {
          menuText: t("数据交易平台"),
          isLink: true,
          url: "https://store.stardust-ai.com/",
          icon: menu_data_03,
        },
      ],
    },
    {
      title: t("价格"),
      url: "/price/",
      menus: [
        {
          menuText: t("定价流程/机制"),
          url: "/price/#pricingProcess",
          icon: menu_price_01,
        },
        {
          menuText: t("标准化服务报价"),
          url: "/price/#labeledPricing",
          icon: menu_price_02,
        },
        {
          menuText: t("预估您的报价"),
          url: "/price/#EstimatePrice",
          icon: menu_price_03,
        },
        {
          menuText: t("标注系统部署"),
          url: "/price/#annotatedDeployment",
          icon: menu_price_04,
        },
      ],
    },
    {
      title: t("公司"),
      url: "/about/",
      menus: [
        {
          menuText: t("公司介绍"),
          url: "/about/#companyIntroduction",
          icon: menu_company_01,
        },
        {
          menuText: t("核心团队"),
          url: "/about/#coreTeam",
          icon: menu_company_02,
        },
        {
          menuText: t("产品架构"),
          url: "/about/#productArchitecture",
          icon: menu_company_03,
        },
        { menuText: t("联系我们"), url: "/contact-us", icon: menu_company_04 },
        { menuText: t("公司新闻"), url: "/news", icon: menu_company_05 },
        { menuText: t("人才招聘"), url: "/recruitment", icon: menu_company_06 },
      ],
    },
    {
      title: t("资源"),
      url: "/download/",
      menus: [
        { menuText: t("文档下载"), url: "/download", icon: menu_download_01 },
        {
          menuText: t("技术文档"),
          isLink: true,
          url: "https://docs.stardust.ai",
          icon: menu_download_02,
        },
      ],
    },
  ]);
  return { menus };
};

export const headerMenuList: ListItem[] = [];

export enum LanguageEnum {
  ZN = "zh-CN",
  EN = "en-US",
}

const Language = ({
  isDark = false,
  className,
}: {
  isDark?: boolean;
  className?: string;
}) => {
  const { changeLanguage, language } = useI18next();
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const { t } = useTranslation();
  const toggleLanguage = async () => {
    if (currentLanguage === LanguageEnum.ZN) {
      await changeLanguage(LanguageEnum.EN);
      setCurrentLanguage(LanguageEnum.EN);
      return;
    }
    await changeLanguage(LanguageEnum.ZN);
    setCurrentLanguage(LanguageEnum.ZN);
  };
  return (
    <MenuButton
      onClick={toggleLanguage}
      isDark={isDark}
      className={classNames(className, "flex items-center justify-center")}
    >
      <LanguageIcon fill={isDark ? "#121437" : "#fff"} />
      <span className="ml-1 text-xs">
        {currentLanguage === LanguageEnum.ZN ? t("EN") : "中文"}
      </span>
    </MenuButton>
  );
};

const MobileMenuView = ({ isDark = false }: { isDark?: boolean }) => {
  const [visible, setVisible] = useState(false);
  const { menus } = useLinkMenus();
  return (
    <>
      <MenuButton
        isDark={isDark}
        className="sm:hidden flex flex-col justify-center items-center last-child-margin-none relative"
        onClick={() => setVisible(true)}
      >
        {new Array(3).fill("").map((item, index) => (
          <div
            key={`${item}_${index}`}
            className={classNames(
              "h-[2px] w-5 mb-1 last-child-margin-none",
              isDark ? "bg-white" : "bg-black"
            )}
          />
        ))}
      </MenuButton>
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        className="mobile-menu-modal"
        centered
      >
        <div className="bg-white rounded-md custom-shadow p-6 z-50 mt-10">
          <div className="flex justify-between w-full mb-4">
            <Language isDark className="text-black" />
            <MenuButton
              onClick={() => setVisible(false)}
              className="flex justify-center items-center bg-gray-400"
            >
              <Close fill="#121437" />
            </MenuButton>
          </div>
          <SearchInput />

          {menus.map((subMenu, index) => (
            <div
              key={index}
              className={classNames(
                "pb-4 mt-4",
                index !== menus.length - 1 && "border-b"
              )}
            >
              <h5 className="text-lg font-bold mb-4">
                <a className="text-black" href={subMenu.url}>
                  {subMenu.title}
                </a>
              </h5>
              <div className="grid grid-cols-2">
                {subMenu.menus.map((navMenu) => (
                  <NavMenu
                    onClick={() => setVisible(false)}
                    key={navMenu.url}
                    url={navMenu.url}
                    isLink={navMenu.isLink}
                  >
                    <img className="w-7 h-7 mr-2" src={navMenu.icon} alt="" />
                    {navMenu.menuText}
                  </NavMenu>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export enum ThemeEnum {
  light = "light",
  dark = "dark",
}

const Header = ({
  defaultTheme = ThemeEnum.light,
}: {
  defaultTheme?: ThemeEnum;
}) => {
  const [isDark, setIsDark] = useState(defaultTheme === ThemeEnum.dark);
  const { language } = useI18next();
  useEffect(() => {
    if (defaultTheme === ThemeEnum.dark) {
      return;
    }
    const scrollHandler = () => {
      const scrollTop = document.documentElement.scrollTop;
      setIsDark(scrollTop > 480);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);
  useLayoutEffect(() => {
    ReactGA.initialize("UA-131901850-1");
    initBaiduAnalysis();
  }, []);
  return (
    <Sticky>
      {({ style }) => (
        <header
          style={{ ...style }}
          className={classNames(
            isDark ? "text-black bg-opacity-30" : "text-white bg-opacity-5",
            "bg-white top-0 header-backdrop-filter z-40 border-b border-opacity-5 border-black relative"
          )}
        >
          <div className="px-6 max-w-[1048px] mx-auto  z-20">
            <div
              className={classNames("h-16 flex items-center justify-between")}
            >
              <div
                onClick={() => (location.href = "/")}
                className="cursor-pointer"
              >
                {isDark ? (
                  language === LanguageEnum.EN ? (
                    <ColorLogoEn />
                  ) : (
                    <ColorLogo />
                  )
                ) : language === LanguageEnum.EN ? (
                  <LogoEn />
                ) : (
                  <Logo />
                )}
              </div>
              <PcMenuView isDark={isDark} />
              <Language
                className="hidden sm:flex cursor-pointer p-2 hover:opacity-70"
                isDark={isDark}
              />
              <MobileMenuView isDark={isDark} />
            </div>
          </div>
        </header>
      )}
    </Sticky>
  );
};

const initBaiduAnalysis = () => {
  // @ts-ignore
  const _hmt = _hmt || [];
  (function () {
    const hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?2082e3951ace55042c34e89008a8cfa1";
    const scripts = document.getElementsByTagName("script");
    const s = scripts[scripts.length - 1];
    s.parentNode?.insertBefore(hm, s);
  })();
};

export default Header;
