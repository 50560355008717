import { useI18next } from "gatsby-plugin-react-i18next";
import { useMediaQuery } from "react-responsive";
import { LanguageEnum } from "../components/Header";

export function isBrowser() {
  return typeof window !== "undefined";
}

export function chunkItems<T>(items: T[], size: number): T[][] {
  return items.reduce((chunks: T[][], _: T, index, orig: T[]) => {
    return !(index % size)
      ? chunks.concat([orig.slice(index, index + size)])
      : chunks;
  }, []);
}

export const useMediaScreen = () => {
  return {
    isSmScreen: useMediaQuery({ query: "(min-device-width: 640px)" }),
  };
};

export const throttle = (callback: Function, delay: number = 200): Function => {
  let lastTime: number = Date.now();
  return (...args: IArguments[]): void => {
    const now = Date.now();
    if (now - delay <= lastTime) {
      return;
    }
    lastTime = now;
    callback.call(null, ...args);
  };
};

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export type Element<T> = T extends Array<infer U> ? U : never;

export const useLanguage = () => {
  const { language } = useI18next();
  return {
    isZhCn: language === LanguageEnum.ZN,
    isEnUs: language === LanguageEnum.EN,
  };
};
