import classNames from "classnames";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo, useState } from "react";
import { Close } from "../../../images";
import { SearchIcon } from "../../Icon";
import { Input } from "../../SearchInput";
import "./searchInput.css";

const HeaderSearchInput = memo(
  ({
    isSearch,
    toggleIsSearch,
    isDark,
  }: {
    toggleIsSearch: () => void;
    isSearch?: boolean;
    isDark: boolean;
  }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    return (
      <div
        className={classNames(
          "header-search-container items-center justify-between absolute left-0 w-full",
          isSearch && "flex"
        )}
      >
        <div className="flex items-center justify-between w-full header-search-input">
          <SearchIcon
            className="mr-4"
            strokeWidth={2}
            stroke={isDark ? "#000" : "#fff"}
          />
          <Input
            autoFocus
            className={classNames(
              "bg-transparent w-6/10 placeholder-white placeholder-opacity-30",
              isDark && "text-black placeholder-black"
            )}
            style={{
              color: isDark ? "#121437" : "#fff",
              caretColor: isDark ? "#121437" : "#fff",
            }}
            placeholder={t("搜索业务需求，如: 人脸识别、语音识别")}
            value={value}
            onChange={(value) => setValue(value)}
            onBlur={() => toggleIsSearch()}
            onSearch={async () => await navigate(`/search?keywords=${value}`)}
          />
        </div>
        <Close
          onClick={() => toggleIsSearch()}
          className="cursor-pointer m-2 opacity-30 hover:opacity-100"
          stroke={isDark ? "#000" : "#fff"}
        />
      </div>
    );
  }
);
export default HeaderSearchInput;
