import classNames from "classnames";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useState } from "react";
import { SearchIcon } from "./Icon";

const SearchInput = (props: { className?: string }) => {
  const [value, setValue] = useState("");
  const handSearch = async () => {
    await navigate(`/search?keywords=${value}`);
  };
  return (
    <div
      className={classNames(
        "rounded-full bg-white border h-10 sm:w-9/12 flex justify-between px-6 items-center",
        props.className
      )}
    >
      <Input
        className="flex-auto text-size-14"
        value={value}
        onChange={(value) => setValue(value)}
        onSearch={handSearch}
      />
      <SearchIcon
        onClick={handSearch}
        className="cursor-pointer"
        strokeWidth={2}
        stroke="#AAAAAA"
      />
    </div>
  );
};

type InputProps = {
  value: string;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onSearch?: () => void;
  onBlur?: () => void;
  style?: React.CSSProperties;
};

export const Input = ({
  value,
  className,
  placeholder,
  onChange,
  onSearch,
  autoFocus = false,
  onBlur,
  style,
}: InputProps) => {
  const { t } = useTranslation();
  return (
    <input
      className={classNames(
        "focus-within:outline-none text-gray-500 w-full",
        className
      )}
      style={style}
      autoFocus={autoFocus}
      type="text"
      value={value}
      placeholder={placeholder ?? t("搜索需求，如: 自动驾驶...")}
      onChange={(e) => onChange?.(e.target.value)}
      onBlur={onBlur}
      onKeyUp={async ({ keyCode }) => {
        if (keyCode === 13 && onSearch != null) {
          onSearch();
        }
      }}
    />
  );
};

export default SearchInput;
