import { useLocation } from "@reach/router";
import classNames from "classnames";
import * as React from "react";
import { memo, useLayoutEffect, useState } from "react";
import { useLanguage } from "../../../utils/utils";
import { SearchIcon } from "../../Icon";
import { useLinkMenus } from "../index";
import HeaderSearchInput from "./HeaderSearchInput";
import "./menu.css";
// @ts-ignore
import { init } from "./menuControl";

const PcMenuView = memo(({ isDark }: { isDark: boolean }) => {
  const [isSearching, setIsSearching] = useState<boolean | undefined>();
  const { menus } = useLinkMenus();
  const pathName = useLocation().pathname;
  const { isEnUs } = useLanguage();
  useLayoutEffect(() => {
    // @ts-ignore
    init();
  }, []);
  return (
    <nav className="navigation w-4/6 relative hidden sm:block">
      <div id="navigation--dropdown" />
      <ul
        className={classNames(
          "navigation__list flex justify-between w-full items-center px-8",
          isDark ? "text-black bg-opacity-30" : "text-white bg-opacity-5"
        )}
      >
        {menus.map((list, index) => {
          return (
            <li
              key={list.title}
              className={classNames(
                "list--item flex-1 flex justify-center",
                `header-menu-${index + 1} header-menu-item`,
                isSearching === undefined
                  ? ""
                  : isSearching
                  ? "is-hidden"
                  : "is-closed"
              )}
            >
              {/* 顶部导航item */}
              <a
                href={list.url}
                className={classNames(
                  "header-nav-menu text-center hover:text-black hover:opacity-50 relative",
                  isDark ? "text-black" : "text-white",
                  !isDark && "hover:text-white"
                )}
              >
                {list.title}
                {(list.url === pathName ||
                  `/en-US${list.url}` === pathName) && (
                  <div
                    className={classNames(
                      "active-nav-menu",
                      isDark ? "bg-black" : "bg-white"
                    )}
                  />
                )}
              </a>
              {/* 下拉菜单 */}
              {list.menus.length > 0 && (
                <ul className="item--dropdown w-auto text-black flex flex-col items-center pt-8">
                  {list.menus.map((menu, index) => {
                    return (
                      <a href={menu.url} key={index} className="w-full">
                        <li
                          key={menu.menuText}
                          className="w-full px-5 py-2 leading-10 text-black hover:bg-blue-100 flex items-center whitespace-nowrap mr-8"
                        >
                          <img
                            className="w-7 h-7 mr-4"
                            src={menu.icon}
                            alt=""
                          />
                          {menu.menuText}
                        </li>
                      </a>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
        <SearchIcon
          onClick={() => setIsSearching(!isSearching)}
          className={classNames(
            "cursor-pointer ml-6 m-2 hover:opacity-50",
            isSearching && "invisible"
          )}
          strokeWidth={2}
          stroke={isDark ? "#000" : "#fff"}
        />
        <HeaderSearchInput
          isSearch={isSearching}
          isDark={isDark}
          toggleIsSearch={() => setIsSearching(!isSearching)}
        />
      </ul>
    </nav>
  );
});

export default PcMenuView;
