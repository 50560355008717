import classNames from "classnames";
import * as React from "react";
import { ReactComponent as Search } from "../images/search.svg";

export const SearchIcon = ({
  stroke,
  strokeWidth = 2,
  strokeOpacity = 1,
  className,
  onClick,
}: {
  stroke: string;
  strokeWidth?: number;
  strokeOpacity?: number;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <Search
      onClick={onClick}
      className={classNames(className)}
      strokeWidth={strokeWidth}
      strokeOpacity={strokeOpacity}
      stroke={stroke}
    />
  );
};
