import { useTranslation } from "gatsby-plugin-react-i18next";
import { useMemo } from "react";

export type HeaderMetadata = {
  title: string;
  keywords: string;
  description: string;
};
/**
 * 首页
 */
export const usePageMetadata = () => {
  const { t } = useTranslation();
  // 首页
  const index = useMemo(() => {
    return {
      title: t("星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"),
      keywords: t(
        "数据标注,标注平台,数据众包,模型训练,所有标注场景,数据安全,数据质量控制"
      ),
      description: t(
        "星尘数据Stardust是专业提供数据标注服务和人工智能数据中台系统的SaaS公司，应用于多类型多场景，为自动驾驶、计算机视觉、语音识别、文本识别、NLP任务等提供高质量的训练数据。"
      ),
    };
  }, [t]);
  // 产品
  const product = useMemo(() => {
    return {
      title: t(
        "产品 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t(
        "数据采集,OCR,NLP,数据算法,车辆标注,语音转录,文本标注,点云,语义分割"
      ),
      description: t(
        "星尘数据平台集标注工具，人工智能算法辅助，质量管控为一体，对数据安全和算法接入提供全方位支持。面向语音识别、自然语言处理、自动驾驶、智能翻译等场景精准提供数据标注和采集的定制化专业服务方案。"
      ),
    };
  }, [t]);
  // 客户案例
  const customerCase = useMemo(() => {
    return {
      title: t(
        "客户案例 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t(
        "数据标注,标注平台,数据众包,模型训练,所有标注场景,数据安全,数据质量控制"
      ),
      description: t(
        "星尘数据Stardust是专业提供数据标注服务和人工智能数据中台系统的SaaS公司，应用于多类型多场景，为自动驾驶、计算机视觉、语音识别、文本识别、NLP任务等提供高质量的训练数据。"
      ),
    };
  }, [t]);
  // 数据
  const data = useMemo(() => {
    return {
      title: t(
        "数据 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t(
        "数据下载,标注平台,数据众包,模型训练,所有标注场景,数据安全,数据质量控制"
      ),
      description: t(
        "星尘数据Stardust是专业提供数据标注服务和人工智能数据中台系统的SaaS公司，应用于多类型多场景，为自动驾驶、计算机视觉、语音识别、文本识别、NLP任务等提供高质量的训练数据。"
      ),
    };
  }, [t]);
  // 价格
  const price = useMemo(() => {
    return {
      title: t(
        "价格 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t(
        "数据标注报价,数据安全,算法支持,车辆标注,人脸识别,语音转录,机器视觉,点云,语义分割"
      ),
      description: t(
        "星尘数据面向多场景数据标注和采集提供全球领先的一站式服务解决方案，根据客户需求，对数据进行多角度多方向的深层次加工，为人工智能发展保驾护航。"
      ),
    };
  }, [t]);
  // 公司
  const about = useMemo(() => {
    return {
      title: t(
        "公司 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t("数据标注平台,标注工具,数据标注公司,众包平台,数据标注师"),
      description: t(
        "星尘数据聚集了国内外人工智能科学家，数据处理数过亿，专注提供行业领先的AI数据中台系统，将数据生产、数据管理、模型训练等环节集中实现，助力人工智能获得高质量训练数据。"
      ),
    };
  }, [t]);
  // 联系我们
  const contactUs = useMemo(() => {
    return {
      title: t(
        "联系我们 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t("数据标注平台,标注工具,数据标注公司,众包平台,数据标注师"),
      description: t(
        "星尘数据聚集了国内外人工智能科学家，数据处理数过亿，专注提供行业领先的AI数据中台系统，将数据生产、数据管理、模型训练等环节集中实现，助力人工智能获得高质量训练数据。"
      ),
    };
  }, [t]);
  // 新闻
  const news = useMemo(() => {
    return {
      title: t(
        "公司新闻 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t(
        "数据标注,标注平台,数据众包,模型训练,所有标注场景,数据安全,数据质量控制"
      ),
      description: t(
        "星尘数据数据众包标注平台-专注于AI人工智能大数据处理服务,致力于人工标注,标注平台,深度学习图像标注,数据标注,数据标注服务,数据标注平台,数据众包,图片标注,文本标注,语音标注,星尘数据等数据标注服务."
      ),
    };
  }, [t]);
  // 人才招聘
  const recruitment = useMemo(() => {
    return {
      title: t(
        "人才招聘 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t(
        "岗位,职位,招聘,校招,数据标注,标注平台,数据众包,模型训练,所有标注场景,数据安全,数据质量控制"
      ),
      description: t(
        "星尘数据数据众包标注平台-专注于AI人工智能大数据处理服务,致力于人工标注,标注平台,深度学习图像标注,数据标注,数据标注服务,数据标注平台,数据众包,图片标注,文本标注,语音标注,星尘数据等数据标注服务."
      ),
    };
  }, [t]);
  // 文档下载
  const download = useMemo(() => {
    return {
      title: t(
        "文档下载 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t("星尘数据,数据众包,标注公司,产品手册,数据服务,开放数据集"),
      description: t(
        "星尘数据平台集标注工具，人工智能算法辅助，质量管控为一体，对数据安全和算法接入提供全方位支持。面向语音识别、文本转化、自动驾驶、智能翻译等场景精准提供数据标注和采集的定制化专业服务方案。"
      ),
    };
  }, [t]);
  // 搜索
  const search = useMemo(() => {
    return {
      title: t(
        "搜索结果 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t(
        "数据标注,标注平台,数据众包,模型训练,所有标注场景,数据安全,数据质量控制"
      ),
      description: t(
        "星尘数据Stardust是专业提供数据标注服务和人工智能数据中台系统的SaaS公司，应用于多类型多场景，为自动驾驶、计算机视觉、语音识别、文本识别、NLP任务等提供高质量的训练数据。"
      ),
    };
  }, [t]);
  // 表单联系页
  const contactForm = useMemo(() => {
    return {
      title: t(
        "需求表单 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t(
        "联系我们,星尘数据,软件服务,数据管理,标注,3D点云,机器视觉,OCR"
      ),
      description: t(
        "星尘数据Stardust是专业提供数据标注服务和人工智能数据中台系统的SaaS公司，应用于多类型多场景，为自动驾驶、计算机视觉、语音识别、文本识别、NLP任务等提供高质量的训练数据。"
      ),
    };
  }, [t]);
  // 隐私条款
  const privacyPolicy = useMemo(() => {
    return {
      title: t(
        "隐私条款 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t("免责声明"),
      description: t(
        "星尘数据Stardust是专业提供数据标注服务和人工智能数据中台系统的SaaS公司，应用于多类型多场景，为自动驾驶、计算机视觉、语音识别、文本识别、NLP任务等提供高质量的训练数据。"
      ),
    };
  }, [t]);
  // 免责声明
  const disclaimer = useMemo(() => {
    return {
      title: t(
        "免责声明 | 星尘数据: 为您的AI模型训提供专业的数据标注服务和标注系统"
      ),
      keywords: t("免责声明"),
      description: t(
        "星尘数据Stardust是专业提供数据标注服务和人工智能数据中台系统的SaaS公司，应用于多类型多场景，为自动驾驶、计算机视觉、语音识别、文本识别、NLP任务等提供高质量的训练数据。"
      ),
    };
  }, [t]);

  return {
    index,
    product,
    customerCase,
    data,
    search,
    price,
    about,
    contactUs,
    news,
    recruitment,
    download,
    contactForm,
    privacyPolicy,
    disclaimer,
  };
};
