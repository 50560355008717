import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { MetadataInfoFragment } from "../../graphql-types";
import { HeaderMetadata } from "../metadata/page";
import { isBrowser } from "../utils/utils";

const Metadata = ({ keywords, title, description }: HeaderMetadata) => {
  const prefix = `${isBrowser() ? window.location.origin : ""}`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <link
        rel="shortcut icon"
        href={`${prefix}/asset/icon/favicon.ico`}
        type="image/x-icon"
      />
      <link
        rel="apple-touch-icon"
        href={`${prefix}/asset/icon/apple-touch-icon.png`}
      />
    </Helmet>
  );
};
export const mapMetadata = (metadata: MetadataInfoFragment) => {
  return {
    title: metadata?.title?.title,
    keywords: metadata?.keywords?.keywords,
    description: metadata?.description?.description,
  };
};

export default Metadata;

export const query = graphql`
  fragment MetadataInfo on ContentfulMetadata {
    keywords {
      keywords
    }
    description {
      description
    }
    title {
      title
    }
  }
`;
