import classNames from "classnames";
import * as React from "react";
import { memo, ReactNode, useState } from "react";
import { ChildrenProps } from "../Container";

type NavItemProps = {
  title: string;
  url: string;
  children: ReactNode;
};

const NavItem = memo((props: NavItemProps) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <nav
      className="relative z-50"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span
        className="cursor-pointer p-2 hover:opacity-70"
        onClick={() => (location.href = props.url)}
      >
        {props.title}
      </span>
      {isHover && (
        <div className="flex flex-col flex-col-reverse items-center h-6 w-40 absolute left-[50%] translate-x-[-50%] top-3">
          <svg
            width="16"
            height="9"
            viewBox="0 0 16 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 0L15.7942 9H0.205771L8 0Z" fill="white" />
          </svg>
          <div className="bg-white w-40 text-black text-center absolute p-4 top-6 left-[50%] translate-x-[-50%] rounded-md z-50 shadow">
            {props.children}
          </div>
        </div>
      )}
    </nav>
  );
});

export const NavMenu = memo(
  (props: {
    children: ReactNode;
    url: string;
    isLink?: boolean;
    onClick?: () => void;
  }) => {
    return (
      <a
        onClick={async () => {
          props.onClick?.();
          setTimeout(() => {
            if (props.isLink) {
              window.open(props.url, "_blank");
              return;
            }
            location.href = props.url;
          }, 500);
        }}
        className="block cursor-pointer text-xs text-black flex items-center my-2 py-2"
      >
        {props.children}
      </a>
    );
  }
);

export const MenuButton = memo(
  ({
    className,
    children,
    isDark,
    onClick,
  }: ChildrenProps & { isDark?: boolean; onClick?: () => void }) => {
    return (
      <div
        onClick={onClick}
        className={classNames(
          "rounded-full bg-opacity-30 w-16 h-8 text-center text-sm leading-8 cursor-pointer",
          isDark ? "bg-gray-400" : "bg-white",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
export default NavItem;
