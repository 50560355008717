import classNames from "classnames";
import * as React from "react";
import { CSSProperties, memo, ReactNode } from "react";
import { StickyContainer } from "react-sticky";

export type ChildrenProps = {
  children?: ReactNode;
  className?: string;
};

export const PageContainer = (props: ChildrenProps) => {
  return (
    <StickyContainer>
      <div className="overflow-hidden">{props.children}</div>
    </StickyContainer>
  );
};

export const MainContainer = (
  props: ChildrenProps & { style?: CSSProperties }
) => {
  return (
    <div
      style={props.style}
      className={classNames(
        "px-6 max-w-[1048px] mx-auto relative z-30",
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export const TopSkewBgContainer = memo(
  ({
    className,
    children,
    fillClassName,
    contentWrapClassName,
  }: ChildrenProps & {
    fillClassName?: string;
    contentWrapClassName?: string;
  }) => {
    return (
      <div
        className={classNames(
          "absolute origin-bottom-right inset-0 z-[-1] -top-[calc(100vw*0.105)] w-full",
          className
        )}
      >
        <div
          className={classNames(
            "origin-top-left -skew-y-6 inset-0 z-[-1] overflow-hidden",
            contentWrapClassName
          )}
        >
          {children}
          <div
            className={classNames("h-1 w-full relative z-10", fillClassName)}
          />
        </div>
      </div>
    );
  }
);

type SkewContainerProps = ChildrenProps & {
  fillClassName?: string;
  hideTop?: boolean;
  hideBottom?: boolean;
  hasPadding?: boolean;
  skewTopChildren?: ReactNode;
  skewBottomChildren?: ReactNode;
};

export const SkewMainContainer = ({
  fillClassName = "dark-blue",
  children,
  className,
  hideBottom,
  hideTop,
  hasPadding = true,
}: SkewContainerProps) => {
  return (
    <SkewContainer
      className={className}
      fillClassName={fillClassName}
      hideBottom={hideBottom}
      hideTop={hideTop}
      hasPadding={hasPadding}
    >
      <MainContainer>{children}</MainContainer>
    </SkewContainer>
  );
};

export const SkewContainer = ({
  fillClassName = "dark-blue",
  children,
  className,
  hideBottom,
  hideTop,
  hasPadding = true,
  skewBottomChildren,
  skewTopChildren,
}: SkewContainerProps) => {
  return (
    <section
      className={classNames(
        "relative",
        hasPadding && "pt-8 pb-12",
        !hideBottom && "mb-[calc(100vw*0.105)]",
        !hideTop && "mt-[calc(100vw*0.105)]",
        fillClassName,
        className
      )}
    >
      {children}
      {!hideBottom && (
        <div
          className={classNames(
            `absolute origin-bottom-right -skew-y-6 inset-0 z-[-1]`,
            fillClassName
          )}
        >
          {skewBottomChildren}
        </div>
      )}
      {!hideTop && (
        <div
          className={classNames(
            `absolute origin-top-left -skew-y-6 inset-0 z-[-1]`,
            fillClassName
          )}
        >
          {skewTopChildren}
        </div>
      )}
    </section>
  );
};
