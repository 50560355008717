import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { MobileContact } from "../images";
import {
  Email,
  Max,
  Min,
  Path,
  Shape,
  Team,
  TopArrow,
} from "../images/index/index";
import { throttle, useMediaScreen } from "../utils/utils";

type ContactItem = {
  icon: ReactNode;
  title: string;
  url: string;
  type: "phone" | "email" | "normal";
  target: string;
};

const FloatContact = () => {
  const { t } = useTranslation();
  const { isSmScreen } = useMediaScreen();
  const [isCollapse, setIsCollapse] = useState(!isSmScreen);
  const [isShow, setIsShow] = useState(false);
  const contactList: ContactItem[] = useMemo(
    () => [
      {
        icon: <Shape />,
        title: t("标注服务: 需求表单"),
        url: "/contact",
        type: "normal",
        target: "",
      },
      // {
      //   icon: <Contact />,
      //   title: t("电话联系我们"),
      //   url: "tel:010-86462864",
      //   type: "phone",
      //   target: "",
      // },
      {
        icon: <Team />,
        title: t("人力团队: 入驻申请"),
        url: "http://stardustai.mikecrm.com/m4EbYrs",
        type: "normal",
        target: "_blank",
      },
      {
        icon: <Email />,
        title: t("技术支持: 发送邮件"),
        url: "mailto:contacts@stardust.ai",
        type: "email",
        target: "",
      },
    ],
    []
  );

  useEffect(() => {
    const listener = throttle(() => {
      setIsShow(window.scrollY > 500);
    }, 50) as EventListener;
    document.addEventListener("scroll", listener);
    return () => document.removeEventListener("scroll", listener);
  }, []);

  if (!isShow) {
    return null;
  }
  return (
    <>
      {isCollapse && !isSmScreen && (
        <div
          onClick={() => setIsCollapse(false)}
          className="w-16 h-16 float-contact-bg flex justify-center items-center rounded fixed right-4 bottom-4 z-50"
          style={{ boxShadow: "0px 4px 8px 0px #0000001A", zIndex: 1000 }}
        >
          <MobileContact />
        </div>
      )}
      <div
        className="fixed right-4 bottom-4 z-50"
        style={{ boxShadow: "0px 4px 8px 0px #0000001A", zIndex: 1000 }}
      >
        {!(!isSmScreen && isCollapse) && (
          <div
            className={classNames(
              "h-12 flex items-center justify-between px-5 py-2 float-contact-bg rounded-t",
              isCollapse && "rounded-b"
            )}
          >
            {!isCollapse && <p className="text-white">{t("如何联系我们？")}</p>}
            <div className="flex items-center">
              <TopArrow
                className="mr-6 cursor-pointer"
                onClick={() => window.scrollTo(0, 0)}
              />
              {isCollapse ? (
                <Max
                  className="cursor-pointer"
                  onClick={() => setIsCollapse(false)}
                />
              ) : (
                <Min
                  className="cursor-pointer"
                  onClick={() => setIsCollapse(true)}
                />
              )}
            </div>
          </div>
        )}
        {!isCollapse && (
          <div className="w-60 rounded-b overflow-hidden">
            {contactList.map((list) => {
              return (
                <div
                  key={list.title}
                  className="bg-white hover:bg-indigo-50 h-12 flex px-5 py-4 border-b last:border-none flex items-center justify-between cursor-pointer "
                >
                  <div className="flex items-center justify-start">
                    {list.icon}
                    <a
                      className="text-black ml-4"
                      href={list.url}
                      target={list.target}
                    >
                      {list.title}
                    </a>
                  </div>
                  <Path />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
export default FloatContact;
